@import 'assets/theme.css';

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/opensans-regular-webfont.woff2') format('woff2'),
  url('assets/fonts/opensans-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/opensans-semibold-webfont.woff2') format('woff2'),
  url('assets/fonts/opensans-semibold-webfont.woff') format('woff');
}

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  background: #f5f5f5;
}

.alert {
  color: #f44336;
}

@media screen and (min-width: 1200px) {
  .xl\:mx-15 {
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }
}

.page-frame {
  padding-top: 50px;
  padding-bottom: 50px;
}

.p-toolbar {
  border: none;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}

.lang-dropdown .p-dropdown {
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  width: 180px;
  height: 2.1rem;
  padding-left: .5rem;
}

.lang-dropdown .p-dropdown-label {
  font-size: small;
}

.lang-dropdown .p-dropdown .p-dropdown-trigger {
  background: #373737;
  color: #ffffff;
  font-size: small;
  width: 2rem;
}

.lang-dropdown .p-icon {
  width: 0.7rem !important;
  height: 0.7rem !important;
}

.lang-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: small;
}

.lang-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #f4f4f4;
}


.lang-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: #f4f4f4;
}

/*Util*/
.rcm-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 12px;
}

/* RCM Button Hover Animation */
@keyframes backgroundSlide {
  from {
    background-size: 0% 100%;
  }
  to {
    background-size: 100% 100%;
  }
}

.p-button {
  position: relative;
  overflow: hidden;
  color: inherit;
}

.p-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fbf769;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.2s ease-in-out;
  z-index: 1;
}

.p-button:hover::before,
.p-button:active::before {
  transform: scaleX(1);
}

.p-button .p-button-label {
  position: relative;
  z-index: 2;
  color: inherit;
}
